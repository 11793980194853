import React from 'react';

import cc_by_sa from '../images/cc_by_sa.png';
import open_access from '../images/open_access_black.png';

import Divider from '../components/Divider';
import dictionary from '../data/_dictionary';

const CreativeCommonsInfo = ({lang, dividerTop, dividerBottom}) => (
    <div className="creative-commons-info">
        {dividerTop && <Divider weak />}
        <div className="creative-commons-info-container">
            <p className="creative-commons-info-text">{dictionary.creativeCommonsInfo[lang]}</p>
            <div className="creative-commons-info-link-container">
                <a className="creative-commons-info-link" href={dictionary._linkOpenAccess[lang]} target="_blank" rel="noopener noreferrer">
                    <img  className="creative-commons-info-logo" src={open_access} alt="open access" />
                </a>
                <a className="creative-commons-info-link" href={dictionary._linkCreativeCommons[lang]} target="_blank" rel="noopener noreferrer">
                    <img  className="creative-commons-info-logo" src={cc_by_sa} alt="open access" />
                </a>
            </div>
        </div>
        {dividerBottom && <Divider weak />}
    </div>
);

export default CreativeCommonsInfo;
